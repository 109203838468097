<template>
    <div style="height: 100%;position:relative;">
        <!--搜索区域-->
        <div class="header">
            <div style="color:#409EFF">
                登录名称：
                <el-input v-model="userName" size="medium" placeholder="请输入内容"></el-input>
            </div>
            <div style="color:#409EFF">
                所属项目：
                <el-input v-model="smartHomeName" size="medium" placeholder="请输入内容"></el-input>
            </div>
            <div style="color:#409EFF;margin-right:20px;">
                账号状态：
                <el-select v-model="status" placeholder="请选择">
                    <el-option
                            v-for="item in statusSel"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </div>

            <el-button type="primary" icon="el-icon-search" @click="getUserList()">搜索</el-button>
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addUserfun">新增</el-button>
        </div>
        <div class="wrapper">
            <el-table :data="userData" style="width: 100%">
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column prop="userName" label="账户名称"></el-table-column>
                <el-table-column prop="smartHomeName" label="所属项目" width="200"></el-table-column>
                <el-table-column prop="createTime" label="注册时间" width="200"></el-table-column>
                <el-table-column prop="deviceStatusColor" label="设备状态" width="200">
                    <template scope="scope">
                        <span style="background:#00C227;border-radius: 50%;"  v-if="scope.row.deviceStatusColor==='green'"></span>
                        <span style="background:#F12727;border-radius: 50%;"  v-if="scope.row.deviceStatusColor==='red'"></span>
                        <span style="background:#F8AD00;border-radius: 50%;"  v-if="scope.row.deviceStatusColor==='orange'"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="账号状态" width="200">
                    <template scope="scope">
                        <span style="border:1px solid #276EF1;color:#276EF1;background:#E1EBFF;padding: 0 5px;border-radius: 3px;" v-if="scope.row.status===1">启动</span>
                        <span style="border:1px solid #D90000;color:#D90000;background:#FFF1F1;padding: 0 5px;border-radius: 3px;" v-else-if="scope.row.status===0">禁用</span>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="操作">
                    <template slot-scope="scope">
                        <div class="operate">
                            <span @click="handleEdit(scope)" style="cursor : pointer;">编辑</span>
                            <span @click="handleDetail(scope)" style="cursor : pointer;">详情</span>
                            <span @click="handleDelete(scope)" style="cursor : pointer;">删除</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!--分页-->
        <Mypage :total="total" :pageSize="limit" @changePage="changePage"/>

        <!--增加编辑对话框-->
        <el-dialog :title="title" :visible.sync="dialogVisible" width="25%">
            <el-form :model="userForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
                <el-form-item label="登录名称" prop="userName">
                    <el-input type="text" v-model="userForm.userName" autocomplete="off" v-bind:disabled="title=='编辑'"></el-input>
                </el-form-item>
                <el-form-item label="所属项目" prop="smartHomeId">
                    <el-select v-model="userForm.smartHomeId" filterable placeholder="请选择">
                        <el-option v-for="item in hotelData" :key="item.smartHomeId" :label="item.smartHomeName" :value="item.smartHomeId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="账号状态" prop="status">
                    <!--<el-radio-group v-model="userForm.status">-->
                        <el-radio v-model="userForm.status" :label="0">禁用</el-radio>
                        <el-radio v-model="userForm.status" :label="1">正常</el-radio>
                    <!--</el-radio-group>-->
                </el-form-item>
                <el-form-item style="text-align: right">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitUserMsg('ruleForm')">确 定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!--详情对话框-->
        <el-dialog title="详情" :visible.sync="detailDialogVisible" width="55%">
            <div style="line-height: 40px;">
                <p style="float:left;margin-right:30px;">账户名称：{{username}}</p>
                <div style="float:left;">
                    <span>设备状态：</span>
                    <el-select v-model="deviceStatus" placeholder="请选择">
                        <el-option v-for="item in roomStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
                <el-button type="primary" icon="el-icon-search" style="float:right;"  @click="getDetailInfo()">搜索</el-button>
            </div>
            <el-table :data="deviceList" style="width: 100%" height="350">
                <el-table-column prop="deviceName"  label="设备名称"></el-table-column>
                <el-table-column  prop="deviceId" label="设备ID" ></el-table-column>
                <el-table-column prop="deviceStatus" label="设备状态" width="100">
                    <template scope="scope">
                        <span style="border:1px solid #666;color:#666;background:#ddd;padding: 0 5px;border-radius: 3px;"  v-if="scope.row.deviceStatus===0">离线</span>
                        <span style="border:1px solid #276EF1;color:#276EF1;background:#E1EBFF;padding: 0 5px;border-radius: 3px;" v-else-if="scope.row.deviceStatus===1">在线</span>
                    </template>
                </el-table-column>
                <el-table-column prop="lastUpTime" label="最后上线时间"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
    import Mypage from '../../../components/MyPagination.vue'
    import {userList,addUser,editUser,userDetail,delUser,hotelList,getDetail} from './user'

    export default {
        components: {
            Mypage
        },
        name: "SmartHomeUser",
        data() {
            return {
                dialogVisible: false, //打开新增模态框
                detailDialogVisible:false, //详情模态框
                title:'新增',
                statusSel: [
                    {
                        value: '',
                        label: '请选择'
                    },{
                    value: '0',
                    label: '禁用'
                }, {
                    value: '1',
                    label: '正常'
                }],
                status:'',
                hotelData:[], //小区列表
                userType:'',
                userName:'',
                username:'', //查询设备状态对话框使用
                roomStatus:[{
                    value: '',
                    label: '全部'
                },{
                    value: '0',
                    label: '离线'
                }, {
                    value: '1',
                    label: '在线'
                }],
                deviceStatus:'',
                deviceList:[],
                smartHomeName:'',
                createTime:'',
                total:10,
                limit: 10,
                page: 1,
                userData: [],
                userId:'',
                //新增用户模态框数据
                userForm: {
                    userName: '', //用户名
                    smartHomeId:'', //所属小区ID
                    status: '', //账号状态
                },
                rules: {
                    userName: [
                        {required: true, message: "请输入手机号", pattern: /^1[3456789]\d{9}$/, trigger: "blur",},
                    ],
                    userType: [
                        {required: true, message: "请选择人员类型", trigger: "change",},
                    ],
                    status: [
                        {required: true, message: "请选择账号状态", trigger: "change"},
                    ],
                }
            }
        },
        methods: {
            //    查询酒店列表
            getUserList() {
                userList(this.status, this.userName, this.smartHomeName, this.limit, this.page,this.$cookies.get("access_token")).then((result) => {
                    if (result["data"].code == 0) {
                        this.userData=result["data"]["data"]["records"]
                        this.total=parseInt(result["data"]["data"]["total"])
                    }else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })
            },
            addUserfun(){
                this.dialogVisible = true;
                this.title='新增'
                this.getHotel()
                this.userForm= {
                    userName: '', //用户名
                    userType: '', //人员类型
                    status: '', //账号状态
                }
            },
            //    编辑
            handleEdit(scope) {
                console.log('scope',scope.row)
                this.dialogVisible = true
                this.title='编辑'
                this.getHotel()
                userDetail(scope.row.userId,this.$cookies.get("access_token")).then((result)=>{
                    if (result["data"].code == 0) {
                        console.log('data',result["data"]["data"])
                        this.userForm=result["data"]["data"]
                    }
                })
                this.userId=scope.row.userId
            },
            getHotel(){
                hotelList(this.$cookies.get("access_token")).then((result) => {
                    if (result["data"].code == 0) {
                        this.hotelData=result["data"]["data"]
                    }else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })
            },
            //详情
            handleDetail(scope){
                console.log('row',scope)
                this.detailDialogVisible=true
                this.username=scope.row.userName
                this.getDetailInfo()

            },
            getDetailInfo(){
                getDetail(this.username,this.deviceStatus,this.$cookies.get("access_token")).then((result) => {
                    if (result["data"].code == 0) {
                        this.deviceList=result["data"]["data"]
                    }else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })
            },
            //    删除
            handleDelete(scope) {
                this.$confirm('确认删除该用户吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    delUser(scope.row.userId,this.$cookies.get("access_token")).then((result)=>{
                        if (result["data"].code == 0) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.getUserList()
                        }else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            // 提交酒店模态框数据
            submitUserMsg(formName) {
                if(this.title=='新增'){
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            console.log('form',this.userForm)
                            addUser(this.userForm,this.$cookies.get("access_token")).then((result)=>{
                                if(result.data.code==0){
                                    this.$message({
                                        message: '添加成功！',
                                        type: 'success'
                                    });
                                    this.dialogVisible = false
                                    this.getUserList();
                                }else {
                                    this.$message({
                                        message: result["data"].message,
                                        type: 'error'
                                    });
                                }

                            }).catch(()=>{
                                this.$message({
                                    message: '请求失败！',
                                    type: 'success'
                                });
                                this.dialogVisible = false
                            })
                        }
                    })
                }else if(this.title=='编辑'){
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            editUser(this.userForm,this.$cookies.get("access_token")).then((result)=>{
                                if(result["data"].code==0){
                                    this.$message({
                                        message: '修改成功！',
                                        type: 'success'
                                    });

                                    this.getUserList();
                                }else {
                                    this.$message({
                                        message: result["data"].message,
                                        type: 'error'
                                    });
                                }
                                this.dialogVisible = false
                            }).catch(()=>{
                                this.$message({
                                    message: '请求失败！',
                                    type: 'error'
                                });
                                this.dialogVisible = false
                            })
                        }
                    })
                }

            },
            //    分页
            changePage(num){
                this.page=num;
                this.getUserList()
            }


        },
        created() {  /*生命周期函数*/
            this.getUserList();
        }
    }
</script>

<style scoped>
    .header {
        display: flex;
    }

    .el-input {
        width: 200px;
        margin: 0 10px;
    }

    .el-button {
        padding: 10px 20px;
    }

    .wrapper {
        margin-top: 20px;
        text-align: center;
    }

    .operate span {
        color: #276EF1;
        margin: 0 5px;
    }

    ::v-deep .el-table th, ::v-deep .el-table td {
        text-align: center !important;
    }

    ::v-deep .el-form-item__label {
        width: 100px
    }
    ::v-deep .el-dialog__header {
        border-bottom:1px solid #ddd;
    }

</style>