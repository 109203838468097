import request from '../../../utils/request'
// const token=localStorage.getItem("access_token");
// console.log('token',token)

//根据查询条件获取用户列表
export function userList(status,userName,smartHomeName,limit,page,token) {
    return request({
        url:"/hotel/SmartHomeUser/user/pageList",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            status:status,
            userName:userName,
            smartHomeName:smartHomeName,
            limit:limit,
            page:page,
        }
    })
}

//新增用户
export function addUser(data,token){
    return request({
        url:"/hotel/SmartHomeUser/user/add",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:data
    })
}
//获取小区列表
export function hotelList(token){
    return request({
        url:"/hotel/smartHome/getAllSmartHome",
        method:"GET",
        headers:{
            'Authorization':'Bearer ' + token
        },
    })
}
//获取用户详情
export function userDetail(userId,token){
    return request({
        url:"/hotel/SmartHomeUser/user/getUpdateDetails",
        method:"GET",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            userId:userId
        }
    })
}
//根据查询条件项目设备详情
export function getDetail(username,status,token) {
    return request({
        url:"/hotel/SmartHomeUser/user/getDetails",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            username:username,
            status:status,
        }
    })
}

//修改用户
export function editUser(data,token){
    return request({
        url:"/hotel/SmartHomeUser/user/update",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:data
    })
}
//删除用户
export function delUser(userId,token){
    return request({
        url:"/hotel/SmartHomeUser/user/remove",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            userId:userId
        }
    })
}